import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';
import React from 'react';
import AccountsNavigation from './AccountsNavigation';
import IndividualAccountsNavigation from './IndividualAccountsNavigation';

export const NAME = 'accounts';

export default class AccountsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'accounts.*';
  }

  getNavigation() {
    return [
      {
        label: 'Accounts',
        icon: 'address-card',
        path: '/accounts',
        permission: 'accounts.*',
        children: [
          {
            label: 'Corporate Accounts',
            icon: 'building',
            path: '/accounts',
            permission: 'accounts.*'
          },
          {
            label: 'Individual Accounts',
            icon: 'address-card',
            path: '/individual-accounts',
            permission: 'accounts.*'
          }
        ]
      }
    ];
  }

  getRoutes() {
    return [
      <AppRoute path="/accounts" component={AccountsNavigation} />,
      <AppRoute
        path="/individual-accounts"
        component={IndividualAccountsNavigation}
      />
    ];
  }
}
