import React from 'react';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import $ from 'jquery';
import BootstrapDateTimePicker from 'eonasdan-bootstrap-datetimepicker';
import _ from 'lodash';
import DatetimepickerCSS from '../../styles/bootstrap/bootstrap-datetimepicker.css';

const theme = createTheme({
  // For Underline Color After Click
  palette: {
    primary: { main: 'rgba(255, 255, 255, 1)' },
  },
  // For Underline Hover Color
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #ffffff!important',
        },
        '&:after': {
          borderBottom: '2px solid #0275d8!important',
        },
      },
      root: {
        // Name of the rule
        color: 'white', // Some CSS
      },
    },
  },
});
export default class InputField extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    if ($(this._input).hasClass('datepicker')) {
      $(this._input).each((index, element) => {
        $(element)
          .datetimepicker({
            useCurrent: false,
            format: 'DD/MM/YYYY',
          })
          .on('dp.change', (e) => {
            const value = e.date.format ? e.date.format('DD/MM/YYYY') : null;
            setTimeout(() => {
              $(element).data('DateTimePicker').hide();
              this.props.handler.handleInputChange(this.props.name, value);
            }, 50);
          });
      });
    }
  }

  /**
   * Get class name
   *
   * @returns {string}
   */
  getClassName() {
    const classNames = ['input-group'];
    if (this.props.fullWidth) {
      classNames.push('fulled');
    }

    return classNames.join(' ');
  }

  _getInputClassName() {
    const classNames = [];

    const { type } = this.props;

    switch (type) {
      case 'date':
        classNames.push('datepicker');
        break;
    }

    return classNames.join(' ');
  }

  /**
   * Handle input change
   *
   * @param e
   */
  handleInputChange(e) {
    if (this.props.handler) {
      this.props.handler.handleInputChange(e);
    }

    this.props.onChange(e);
  }

  getValidationError() {
    const messages = [];

    if (!this.props.handler) {
      return null;
    }

    this.props.handler
      .getErrors()
      .filter((x) => x.field == this.props.name)
      .forEach((error) => messages.push(error.message));
    if (messages.length == 0) {
      return null;
    }
    return (
      <div className="validation-message" key={`${this.props.name}-error`}>
        {messages.join('<br/>')}
      </div>
    );
  }

  _getType() {
    let { type } = this.props;

    if (!type) {
      type = 'text';
    }

    if (type == 'date') {
      type = 'text';
    }

    return type;
  }

  _getNote() {
    if (!this.props.note) return null;

    return (
      <em style={{ position: 'absolute', right: 0, bottom: -20 }}>
        {this.props.note}
      </em>
    );
  }

  /**
   * Render input
   *
   * @returns {XML}
   */
  render() {
    const inputProps = _.get(this.props.materialProps, 'inputProps', {});

    let className = _.get(inputProps, 'className', '');

    className += ` ${this._getInputClassName()}`;

    inputProps.className = className;

    inputProps.ref = (ref) => (this._input = ref);

    const materialProps = {
      ...this.props.materialProps,
      inputProps,
    };

    const containerClassName = this.getClassName();

    const type = this._getType();

    const validationError = this.getValidationError();
    return (
      <div className={containerClassName}>
        <MuiThemeProvider theme={theme}>
          <TextField
            ref="actualInput"
            autoComplete={this.props.autoComplete}
            label={this.props.label}
            type={type}
            name={this.props.name}
            inputProps={this.props.inputProps}
            value={this.props.value}
            multiline={type == 'textarea'}
            onChange={this.handleInputChange.bind(this)}
            {...materialProps}
          />
          {this._getNote()}
        </MuiThemeProvider>
        {validationError}
      </div>
    );
  }
}

InputField.defaultProps = {
  onChange: (e) => {},
};
