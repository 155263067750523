import Api from '@launchpad/logic/api/Api';
import BaseModel from '@launchpad/logic/model/BaseModel';
import { OverrideService } from '@launchpad/logic/services';

export default class UploadKycKybDocumentModel extends BaseModel {
  getSubmitUrl() {
    return this.data.isKyb
      ? `admin/${OverrideService.getConfig().pluginName ||
          process.env.REACT_APP_ADMIN_VARIANT}
/onboarding/upload-kyc-documents-organisation`
      : `admin/${OverrideService.getConfig().pluginName ||
          process.env.REACT_APP_ADMIN_VARIANT}
/onboarding/upload-kyc-documents`;
  }

  getCustomHeaders() {
    return {
      'Content-Type': 'multipart/form-data'
    };
  }

  async deleteDocument(id) {
    const result = await Api.call(
      `${OverrideService.getConfig().pluginName ||
        process.env.REACT_APP_ADMIN_VARIANT}/delete-kyc-document/${id}`,
      'DELETE'
    );

    console.log('result', result);

    return result.data;
  }
}
