/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import {
  FormHandler,
  FormSubmitWrapper,
  ModalDialog,
  OverrideService
} from '@launchpad';
import UploadKycDocument from './upload-kyc-document/upload-kyc-document.component';
import UploadKycKybDocumentModel from '../models/UploadKycKybDocumentModel';
import InputField from '@launchpad/components/crud/InputField';

export default class UploadDocumentModal extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.formHandler = new FormHandler(
      this,
      new UploadKycKybDocumentModel({
        isKyb: props.kyb,
        file: null,
        documentType: ''
      })
    );
  }

  onSubmitSuccess() {
    this.props.onClose();
  }

  getSubmitData(record) {
    const data = new FormData();
    data.append('filename', record.file.name);
    data.append('type', record.documentType);
    data.append('organizationId', this.props.organisationId);
    if (this.props.uboId) {
      data.append('internalReference', this.props.uboId);
    }
    data.append('userId', this.props.userId);
    data.append('documentName', record.file.name);
    data.append('Documents', record.file);

    if (
      record.documentType === 'OTHER' &&
      OverrideService.getConfig().allowOtherDocumentDescription
    ) {
      data.append('additionalData[name]', record.docDescription);
      data.append('additionalData[description]', record.docDescription);
    }

    return data;
  }

  render() {
    const { formHandler } = this;
    return (
      <ModalDialog
        show={this.props.showModal}
        onClose={() => this.props.onClose()}
      >
        <div>
          <h3>Upload document</h3>
          <div
            className="element-with-blur"
            style={{ paddingTop: '2px', marginRight: 20 }}
          >
            <div className="add-new-group" style={{ position: 'relative' }}>
              <div className="container">
                <FormSubmitWrapper
                  formHandler={formHandler}
                  buttonText="Submit"
                  customCloseFunction={this.props.onClose}
                  disabled={
                    !this.state.record.documentType || !this.state.record.file
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}
                  >
                    <span style={{ marginBottom: 5 }}>Document type:</span>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: 20,
                        flexDirection: 'column'
                      }}
                    >
                      <select
                        className="option-wrapper"
                        onChange={event => {
                          const { value } = event.target;
                          this.setState(prevState => ({
                            record: {
                              ...prevState.record,
                              documentType: value
                            }
                          }));
                        }}
                        value={this.state.record.documentType}
                      >
                        <option disabled value="">
                          -- select an option --
                        </option>
                        {this.props.documentTypes?.length > 0 ? (
                          this.props.documentTypes.map(type => (
                            <option key={type.value} value={type.value}>
                              {type.label}
                            </option>
                          ))
                        ) : (
                          <>
                            <option value="COI">
                              Certificate of incorporation
                            </option>
                            <option value="MOA">
                              Memorandum of Association
                            </option>
                            <option value="CPOA">
                              Proof of address - Company
                            </option>
                            <option value="AOA">Articles of Association</option>
                            <option value="OTHER">Other</option>
                          </>
                        )}
                      </select>
                      {this.state.record.documentType === 'OTHER' &&
                        OverrideService.getConfig()
                          .allowOtherDocumentDescription && (
                          <InputField
                            className="input-group"
                            materialProps={{
                              fullWidth: true
                            }}
                            autoComplete={this.state.record.docDescription}
                            label="Document description"
                            name="docDescription"
                            // placeholder={'Enter E-mail'}
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={this.state.record.docDescription}
                            handler={formHandler}
                          />
                        )}
                    </div>
                  </div>
                  {this.state.record.documentType && (
                    <>
                      <UploadKycDocument
                        allowPdf
                        onSelectFile={file =>
                          formHandler.handleInputChange('file', file)
                        }
                        onClearFile={() =>
                          formHandler.handleInputChange('file', null)
                        }
                      />
                    </>
                  )}
                </FormSubmitWrapper>
              </div>
            </div>
          </div>
        </div>
      </ModalDialog>
    );
  }
}
