/* eslint-disable @typescript-eslint/no-empty-function */
import { setAuthorizationAction } from '@launchpad/modules/auth/AuthActions';
import ToastHelper from '@launchpad/util/ToastHelper';
import axios, { Method } from 'axios';
import { OverrideService } from '@launchpad';
import Logger from '../../util/Logger';
// import store from '../store/AppStore';
import Config from '../../config';
import store from '../store/AppStore';
import SCAService from './SCAService';
import Auth from '../services/AuthService';

let authStrategy: string | null = null;
let accessToken: string | null = null;
let refreshTokenPromise: Promise<any> | null = null;

export default class Api {
  static getBaseUrl() {
    if (Config.env === 'live') {
      return Config.url;
    }
    return Config.url;
  }

  static resolveToken() {
    return accessToken || store.getState().auth.token || null;
  }

  static setToken(token: string): void {
    accessToken = token;
  }

  static getProvidedDeviceId(): string | null {
    return Auth.getAuthStorage().getItem('uuid');
  }

  static call(
    url: string,
    method: Method = 'POST',
    data = {} as any,
    onProgress: any = null,
    isRefresh = false,
    customHeaders = {},
    apiCallId?: string
  ) {
    // Set auth strategy
    if (!authStrategy) {
      authStrategy = OverrideService.getConfig().authStrategy;
    }

    const authToken = this.resolveToken();
    const requestUrl = this.getBaseUrl() + url;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const ajaxOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        'X-Device-Os': 'WEB',
        'Provided-Device-Id': Auth.getAuthStorage().getItem('uuid'),
        Timezone: timezone,
        ...customHeaders
      },
      contentType: 'application/json',
      body:
        customHeaders['Content-Type'] === 'multipart/form-data'
          ? data
          : JSON.stringify(data)
    };

    if (authToken !== null) {
      ajaxOptions.headers.Authorization = `Bearer ${authToken}`;
    }
    Logger.log('api options', requestUrl, ajaxOptions);
    Logger.log('api request', method, requestUrl, data);

    return new Promise((resolve, reject) => {
      // const config =  {
      //   onUploadProgress: function(progressEvent: any) {
      //     var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
      //   }
      // }

      const axiosOptions: Record<string, unknown> = {
        url: this.getBaseUrl() + url,
        method,
        // contentType: 'application/json',
        // withCredentials: true,
        headers: ajaxOptions.headers,
        withCredentials: authStrategy !== 'access-token',
        onUploadProgress(progressEvent: any) {
          if (onProgress) {
            onProgress(progressEvent);
          }
        }
      };

      /**
       * TODO: Check if some method needs to be added here
       */
      // if (['GET', 'DELETE'].includes(String(method).toUpperCase())) {
      if (['GET'].includes(String(method).toUpperCase())) {
        // Extract params from url and combine with data
        // Without this get params are duplicated in url
        const urlData = getUrlInfo(requestUrl);
        axiosOptions.url = urlData.url;
        axiosOptions.params = {
          ...(urlData.searchParams as Record<string, string>),
          ...data
        };
      } else {
        axiosOptions.data =
          customHeaders['Content-Type'] === 'multipart/form-data'
            ? data
            : JSON.stringify(data);
      }

      axios(axiosOptions)
        .then((response: any) => {
          Logger.log('api response', requestUrl, response);
          if (!response.data.success) {
            ToastHelper.show(response.data.errors[0].message);
            reject();
          }
          resolve(response.data);
        })
        .catch((error: any) => {
          if (error.response) {
            Logger.log('data', error.response);

            if (error.response.status === 401) {
              const logoutProcedure = (doRedirection = false) => {
                localStorage.removeItem('AUTH_TOKEN');
                if (doRedirection) {
                  window.location.replace('/login');
                }
                window.localStorage.setItem('APP_TABS_ACTIVE_TAB', '');
              };

              // There is no refresh procedure if we are using access-token strategy
              if (authStrategy === 'access-token') {
                logoutProcedure(true);
                return;
              }

              // If this is a failed call after for token refresh - logout because refresh token is bad
              if (isRefresh) {
                logoutProcedure();
                resolve(false);
                return;
              }

              // Try to refresh the token
              this.refreshToken()
                .then(() => {
                  this.call(url, method, data, onProgress).then(d =>
                    resolve(d)
                  );
                  refreshTokenPromise = null;
                })
                .catch(() => {
                  logoutProcedure(true);
                  refreshTokenPromise = null;
                });

              return;
            }

            // Handle sca response
            if (
              error.response.status === 412 &&
              OverrideService.getConfig().twoFa
            ) {
              // Set publicHash
              store.dispatch(
                setAuthorizationAction({
                  ...error.response.data.errors[0].payload,
                  requestData: data,
                  publicHash: error.response.data.errors[0].payload.publicHash
                })
              );
              // Handle how user will enter OTP
              SCAService.handleScaCode(
                url,
                apiCallId,
                error.response.data.errors[0].payload
              );
              // Do not finish promise, wait for SCA action to finished
              return;
            }

            if (error.response.status === 200) {
              const { code } = error.response.data;

              if (code === 401) {
                localStorage.removeItem('AUTH_TOKEN');
                window.location.replace('/login');
                window.localStorage.setItem('APP_TABS_ACTIVE_TAB', '');
                // store.dispatch(logoutTriggerAction())
              }
            } else if (error.response.status === 500) {
              if (
                error.response.data.errors &&
                error.response.data.errors.length > 0
              ) {
                ToastHelper.show(error.response.data.errors[0].message);
              }
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
          } else {
            // Something happened in setting up the request that triggered an Error
          }
          reject(error.response ? error.response.data : error);
        });
    });
  }

  static async guardedDownload(url) {
    const response: any = await this.call('tools/download/trigger', 'POST', {
      file: url
    });

    if (response.data.url) {
      window.location = response.data.url;
    }
  }

  /**
   * Download file
   */
  static downloadFile(
    url,
    method,
    params = {},
    onDownloadStart = () => {},
    onDownloadEnd = () => {}
  ) {
    const headers = {
      'Provided-Device-Id': Auth.getAuthStorage().getItem('uuid')
    } as any;
    // const { token } = store.getState().auth;
    const token = this.resolveToken();
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    if (onDownloadStart) {
      onDownloadStart();
    }

    axios({
      url: this.getBaseUrl() + url,
      method: method || 'POST',
      headers,
      responseType: 'blob',
      data: JSON.stringify(params)
    })
      .then(response => {
        const disposition = response.headers['content-disposition'];
        const filename = decodeURI(disposition.match(/filename="(.*)"/)[1]);
        const linkUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = linkUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        if (onDownloadEnd) {
          onDownloadEnd();
        }
      });
  }

  static async downloadFileFromUrl(
    url: string,
    method: string,
    params = {},
    onDownloadStart = () => {},
    onDownloadEnd = () => {}
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = {
        'Provided-Device-Id': Auth.getAuthStorage().getItem('uuid')
      } as any;
      // const { token } = store.getState().auth;
      const token = this.resolveToken();

      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      if (onDownloadStart) {
        onDownloadStart();
      }

      axios({
        url: this.getBaseUrl() + url,
        method: (method || 'POST') as Method,
        headers,
        data: JSON.stringify(params)
      })
        .then(response => {
          const link = document.createElement('a');
          link.href = response.data?.data?.file;
          link.setAttribute(
            'download',
            response.data.data.fileName ||
              (Math.random() + 1).toString(36).substring(7)
          );
          document.body.appendChild(link);
          link.click();

          resolve();
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(`Error while downloading file from ${url}`, e);

          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors.length > 0
          ) {
            reject(new Error(e.response.data.errors[0].message));
          }

          // Intentionally resolving this to true, until sure that all uncaught promise rejections are handled
          reject(e);
        })
        .finally(() => {
          if (onDownloadEnd) {
            onDownloadEnd();
          }
        });
    });
  }

  static refreshToken() {
    // If already initialized promise - use it
    if (refreshTokenPromise) {
      return refreshTokenPromise;
    }

    // Refresh token is stored in promise variable, to avoid repeating it multiple times
    // For example, on page refresh, multiple API calls may get 401, and refreshToken() will be called for each
    // This way, we ensure that only one goes through
    refreshTokenPromise = new Promise((resolve, reject) => {
      return Api.call('admin/auth/session/refresh', 'GET', {}, false, true)
        .then((data: any) => {
          if (data?.data?.accessToken) {
            this.setToken(data.data.accessToken);
            window.localStorage.setItem('AUTH_TOKEN', '*********');
            resolve(true);
          }
          reject();
        })
        .catch(e => {
          reject(e);
        });
    });

    return refreshTokenPromise;
  }
}

/**
 * Extracts get parameters from url and returns both url and get params in object
 *
 * @param urlString
 * @returns
 */
const getUrlInfo = (urlString: string): Record<string, unknown> => {
  const url = new URL(urlString);
  return {
    url: url.origin + url.pathname,
    searchParams: Object.fromEntries(url.searchParams)
  };
};
