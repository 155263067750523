import { AppRoute } from '@launchpad';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Accounts from './Accounts';
// import BulkLoadDashboard from './bulk-load/BulkLoadDashboard';
import AccountDetails from './details/AccountDetails';

export default class IndividualAccountsNavigation extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route path="/accounts/details/:id" component={AccountDetails} />
        {/* <AppRoute path="/accounts/bulk-load" component={BulkLoadDashboard} /> */}
        <AppRoute path="/individual-accounts" component={Accounts} />
      </Switch>
    );
  }
}
